import { Language } from '~/constants/languages';
import Config from '~/lib/config';

const TTS_URI = `https://texttospeech.googleapis.com/v1/text:synthesize?key=${Config.GOOGLE_TEXT_TO_SPEECH_KEY}`;
const SAMPLE_RATES = 16000;

enum TTSService {
  Google = 'google',
  Azure = 'azure',
}

type VoiceType = {
  language: Language;
  languageCode: string;
  name: string;
  service: TTSService;
};

export const voices: VoiceType[] = [
  {
    language: Language.French,
    languageCode: 'fr-FR',
    name: 'fr-FR-Wavenet-E',
    service: TTSService.Google,
  },
  {
    language: Language.German,
    languageCode: 'de-DE',
    name: 'de-DE-Wavenet-C',
    service: TTSService.Google,
  },
  {
    language: Language.Italian,
    languageCode: 'it-IT',
    name: 'it-IT-Wavenet-A',
    service: TTSService.Google,
  },
  {
    language: Language.Japanese,
    languageCode: 'ja-JP',
    name: 'ja-JP-Wavenet-D',
    service: TTSService.Google,
  },
  {
    language: Language.Korean,
    languageCode: 'ko-KR',
    name: 'ko-KR-Wavenet-A',
    service: TTSService.Google,
  },
  {
    language: Language.Portuguese,
    languageCode: 'pt-BR',
    name: 'pt-BR-Wavenet-A',
    service: TTSService.Google,
  },
  {
    language: Language.Spanish,
    languageCode: 'es-ES',
    name: 'es-ES-Standard-A',
    service: TTSService.Google,
  },
  {
    language: Language.Spanish,
    languageCode: 'es-ES',
    name: 'es-ES-Standard-A',
    service: TTSService.Google,
  },
  {
    language: Language.English,
    languageCode: 'en-US',
    name: 'en-US-Wavenet-H',
    service: TTSService.Google,
  },
  {
    language: Language.Chinese,
    languageCode: 'cmn-CN',
    name: 'cmn-CN-Wavenet-A',
    service: TTSService.Google,
  },
  {
    language: Language.Arabic,
    languageCode: 'ar-XA',
    name: 'ar-XA-Wavenet-D',
    service: TTSService.Google,
  },
  {
    language: Language.Hebrew,
    languageCode: 'he-IL',
    name: 'he-IL-HilaNeural', // female, sounds great
    // name: 'he-IL-Asaf', // male, sounds bad
    // name: 'he-IL-AvriNeural', // male, sounds ok
    service: TTSService.Azure,
  },
  {
    language: Language.Hindi,
    languageCode: 'hi-IN',
    name: 'hi-IN-Wavenet-D',
    service: TTSService.Google,
  },
];

const getVoice = (language: Language) => {
  return voices.find((languageVoice) => languageVoice.language === language);
};

const createRequestOptions = (text: string | Array<string>, voice: VoiceType | undefined) => {
  if (!voice) {
    return null;
  }

  return {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      input: {
        text: Array.isArray(text) ? text[0] : text,
      },
      voice: {
        languageCode: voice.languageCode,
        name: voice.name,
        ssmlGender: 'FEMALE',
      },
      audioConfig: {
        audioEncoding: 'MP3',
        sampleRateHertz: SAMPLE_RATES,
      },
    }),
  };
};

const convertBase64Mp3ToAudioUrl = (base64: string) => {
  const raw = window.atob(base64);
  const rawLength = raw.length;
  const binary = new Uint8Array(new ArrayBuffer(rawLength));

  for (let i = 0; i < rawLength; i++) {
    binary[i] = raw.charCodeAt(i);
  }

  const blob = new Blob([binary], {
    type: 'audio/mp3',
  });
  return URL.createObjectURL(blob);
};

/**
 * gets the auth token from Azure
 * @returns auth token
 */
const azureAccessToken = async () => {
  const issueTokenEndpoint = 'https://westus2.api.cognitive.microsoft.com/sts/v1.0/issuetoken';

  const options = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': Config.AZURE_TEXT_TO_SPEECH_KEY,
      'Content-Length': '0',
      'Content-type': 'application/x-www-form-urlencoded',
    },
  };

  try {
    const response = await fetch(issueTokenEndpoint, options);
    return response.text();
  } catch (error: any) {
    throw new Error(error);
  }
};

/**
 * requests TTS from Azure
 *
 * https://docs.microsoft.com/en-us/azure/cognitive-services/speech-service/rest-text-to-speech
 * @param accessToken jwt token
 * @param text in-language copy
 * @param language determines what voice to use
 * @returns audio blob
 */
const getAzureTextToSpeech = async (accessToken: string, text: string | Array<string>, voice: VoiceType) => {
  // Create the SSML request.
  const body = `<speak version="1.0" xml:lang="${voice.languageCode}"><voice xml:lang="${voice.languageCode}" name="${
    voice.name
  }">${Array.isArray(text) ? text[0] : text}</voice></speak>`;

  const audioOutputFormat = 'audio-16khz-32kbitrate-mono-mp3';
  const applicationName = 'Text-To-Speech-Toucan';
  const voiceEndpoint = 'https://westus2.tts.speech.microsoft.com/cognitiveservices/v1';

  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/ssml+xml',
      'X-Microsoft-OutputFormat': audioOutputFormat,
      'User-Agent': applicationName,
    },
    body,
  };

  try {
    const response = await fetch(voiceEndpoint, options);
    return response.blob();
  } catch (error: any) {
    throw new Error(error);
  }
};

export const textToSpeech = async (text: string | string[], language: Language) => {
  const voice = getVoice(language);

  if (voice?.service === TTSService.Azure) {
    const accessToken = await azureAccessToken();
    const azureTTS = await getAzureTextToSpeech(accessToken, text, voice);
    return URL.createObjectURL(azureTTS);
  }

  const options = createRequestOptions(text, voice);
  if (!options) {
    throw new Error(`Unable to fetch audio file for "${text}" in "${language}"`);
  }
  const resp = await fetch(TTS_URI, options);
  const json = await resp.json();

  if (!resp.ok) {
    throw new Error(json.error.message);
  }
  const { audioContent } = json;

  return convertBase64Mp3ToAudioUrl(audioContent);
};
