import { useContext, useEffect } from 'react';

import { NextSeo, NextSeoProps } from 'next-seo';

import { SeoContext } from '~/context/SeoContext';

export const SeoOverrides = (overrides: NextSeoProps) => {
  const { setOptions } = useContext(SeoContext);
  // Ensure correct data in client-side renders
  useEffect(() => {
    setOptions(overrides);
    return () => setOptions({});
  });
  // Ensure correct data in server-side render
  return <NextSeo {...overrides} />;
};
