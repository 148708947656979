import { useState, ReactElement, ReactNode, Ref, useEffect } from 'react';

import { css } from '@emotion/react';
import { ToucanColors, ToucanComponents } from '@jointoucan/toucan-design';
import { Box } from '@mui/material';
import { useTranslation } from 'next-i18next';

import { Language } from '~/constants/languages';
import { useOpenSettingsMutation } from '~/extension/schema';
import { getPronunciation } from '~/hooks/useToucanTranslation';
import { textToSpeech, voices } from '~/lib/text-to-speech';

import { Pronunciation } from './Pronunciation';

const {
  Highlight: {
    Highlight,
    HighlightHover,
    HighlightHoverHeader,
    AudioPlayer,
    HighlightHoverBody,
    Info,
    HighlightHoverFooter,
    Action,
    HighlightHoverBefore,
    PrimaryAction,
  },
} = ToucanComponents;

interface InlineTranslationBaseProps {
  children: string | Array<string>;
  sourcePhrase: string;
  highlightProps?: Partial<{
    ref?: Ref<HTMLSpanElement>;
    onFocus?: (e: FocusEvent) => void;
    onBlur?: (e: FocusEvent) => void;
    isLightText?: boolean;
    active: boolean;
    hasCorner?: boolean;
    cornerColor?: string;
    highlightColor?: string;
    outerContent?: ReactNode;
    display?: string;
  }>;
  highlightHoverProps?: Partial<{
    node: HTMLElement;
    disablePortal?: boolean;
    isDarkMode?: boolean;
    isOpen: boolean;
    onFocus?: (e: FocusEvent) => void;
    onBlur?: (e: FocusEvent) => void;
    popperModifiers?: any;
    PopperProps?: any;
  }>;
  showSaveWord?: boolean;
  showKnownWord?: boolean;
  showSettings?: boolean;
  onOpen?: (translation: string) => void;
}

interface InlineTranslationWithAudioFile extends InlineTranslationBaseProps {
  audioFileUrl: string;
  language?: never;
}

interface InlineTranslationWithLanguage extends InlineTranslationBaseProps {
  language: Language;
  audioFileUrl?: never;
}

type InlineTranslationProps = InlineTranslationWithAudioFile | InlineTranslationWithLanguage;

export const InlineTranslation = ({
  children,
  sourcePhrase,
  audioFileUrl,
  language,
  highlightProps,
  highlightHoverProps,
  showSaveWord,
  showKnownWord,
  showSettings,
  onOpen,
}: InlineTranslationProps): ReactElement => {
  const { t } = useTranslation();
  const [isWordSaved, setWordSaved] = useState(false);
  const [isKnown, setIsKnown] = useState(false);
  const [activeTranslationNode, setActiveTranslationNode] = useState<ReactNode>();
  const [availablePronunciation, setAvailablePronunciation] = useState<null | string>(null);
  const [openSettingsInExtension] = useOpenSettingsMutation();

  const onFocusHighlight = (event: FocusEvent) => {
    if (activeTranslationNode) {
      return;
    }

    setActiveTranslationNode(event.target);
    onOpen?.(Array.isArray(children) ? children[0] : children);
  };

  const onBlurHighlight = () => {
    setActiveTranslationNode(null);
  };

  const onClickPlayAudio = async () => {
    const audioFileToPlay = new Audio(audioFileUrl || (await textToSpeech(children, language as Language)));

    const audioInterface = {
      play: () => {
        audioFileToPlay.play();
        audioFileToPlay.addEventListener('ended', () => audioInterface.onended());
      },
      onended: () => {},
    };

    return audioInterface;
  };

  useEffect(() => {
    if (language) {
      const pronunciation = getPronunciation(language, sourcePhrase) || null;
      setAvailablePronunciation(pronunciation);
    }
  }, [children]);

  return (
    <Highlight active onFocus={onFocusHighlight} onBlur={onBlurHighlight} {...highlightProps}>
      {children}
      <span
        css={css`
          cursor: default;
          background-color: green;
          margin-bottom: none;
        `}
      >
        <HighlightHover
          node={activeTranslationNode as HTMLElement}
          isOpen={!!activeTranslationNode}
          {...highlightHoverProps}
        >
          {availablePronunciation && (
            <HighlightHoverBefore>
              <Pronunciation pronunciation={availablePronunciation} />
            </HighlightHoverBefore>
          )}
          <HighlightHoverHeader>
            {/* hide audio button if unavailable */}
            {voices.some((voice) => voice.language === language) && (
              <AudioPlayer onAudioPlayRequested={onClickPlayAudio} />
            )}
            <Box flex="1" display="flex" flexDirection="row" justifyContent="flex-end">
              {showSaveWord && (
                <Box pl={1}>
                  <PrimaryAction
                    icon={isWordSaved ? 'bookmark-solid' : 'bookmark-outline'}
                    title={t('save')}
                    onClick={() => setWordSaved(!isWordSaved)}
                    backgroundColor={isWordSaved ? ToucanColors.yellow : ToucanColors.gray}
                    backgroundHoverColor={ToucanColors.yellow}
                    backgroundActiveColor={ToucanColors.yellow}
                    color={isWordSaved ? ToucanColors.yellow : ToucanColors.gray}
                    hoverColor={ToucanColors.yellow}
                    activeColor={ToucanColors.yellow}
                    borderActiveColor={ToucanColors.yellow}
                    colorShade={{
                      background: {
                        dark: {
                          idle: isWordSaved ? 300 : 600,
                          hover: 500,
                          active: 500,
                        },
                        light: {
                          idle: isWordSaved ? 300 : 100,
                          hover: 100,
                          active: 100,
                        },
                      },
                      color: {
                        dark: {
                          idle: isWordSaved ? 500 : 400,
                          hover: 300,
                          active: 300,
                        },
                        light: {
                          idle: isWordSaved ? 500 : 300,
                          hover: 300,
                          active: 300,
                        },
                      },
                      border: {
                        dark: {
                          active: 300,
                        },
                        light: {
                          active: 300,
                        },
                      },
                    }}
                  />
                </Box>
              )}
              {showKnownWord && (
                <Box pl={1}>
                  <PrimaryAction
                    icon="check"
                    title={t('iKnowThis')}
                    onClick={() => setIsKnown(!isKnown)}
                    backgroundColor={isKnown ? ToucanColors.green : ToucanColors.gray}
                    backgroundHoverColor={ToucanColors.green}
                    backgroundActiveColor={ToucanColors.green}
                    color={isKnown ? undefined : ToucanColors.gray}
                    hoverColor={ToucanColors.green}
                    activeColor={ToucanColors.green}
                    borderActiveColor={ToucanColors.green}
                    colorShade={{
                      background: {
                        dark: {
                          idle: isKnown ? 300 : 600,
                          hover: 400,
                          active: 400,
                        },
                        light: {
                          idle: isKnown ? 300 : 100,
                          hover: 100,
                          active: 100,
                        },
                      },
                      color: {
                        dark: {
                          idle: isKnown ? ToucanColors.white : 400,
                          hover: 200,
                          active: 200,
                        },
                        light: {
                          idle: isKnown ? ToucanColors.white : 300,
                          hover: 300,
                          active: 300,
                        },
                      },
                      border: {
                        dark: {
                          active: 200,
                        },
                        light: {
                          active: 200,
                        },
                      },
                    }}
                  />
                </Box>
              )}
            </Box>
          </HighlightHoverHeader>
          <HighlightHoverBody>
            <Info title={sourcePhrase} />
          </HighlightHoverBody>
          <HighlightHoverFooter>
            <img
              src="/images/logo.svg"
              alt="toucan-logo"
              css={css`
                max-width: 208px;
                height: 100%;
              `}
            />
            <Box flex="1" />
            <Box display="flex" justifyContent="space-between">
              <Box pr={1}>
                {showSettings && (
                  <Action
                    icon="cog"
                    title={t('settings')}
                    onClick={() => {
                      openSettingsInExtension({});
                    }}
                    color={ToucanColors.gray}
                    hoverColor={ToucanColors.blue}
                    darkHoverColor={ToucanColors.teal}
                    activeColor={ToucanColors.blue}
                    darkActiveColor={ToucanColors.teal}
                    colorShade={{
                      light: {
                        idle: 300,
                        hover: 200,
                        active: 400,
                      },
                      dark: {
                        idle: 400,
                        hover: 200,
                        active: 400,
                      },
                    }}
                  />
                )}
              </Box>
            </Box>
          </HighlightHoverFooter>
        </HighlightHover>
      </span>
    </Highlight>
  );
};
