export enum ExistingPartner {
  // 2dkiri = '2DKiri', // omitting as enums cannot start with a number
  audiochannel = 'Audio Channel',
  bark = 'Bark',
  bart = 'Simple Gmail Notes',
  battletabs = 'BattleTabs',
  beyond20 = 'Beyond 20',
  bold = 'Bold',
  comfable = 'UV Weather',
  ddict = 'Ddict',
  darkness = 'Darkness',
  darkreader = 'Dark Reader',
  disneyplusparty = 'Disney Plus Party',
  exifviewer = 'EXIF Viewer Pro',
  fgsquared = 'FGsquared',
  fiveable = 'Fiveable',
  giggle = 'Your Typical Giggle',
  hoaiartworks = 'HOAIartworks',
  highcode = 'QR Code Generator',
  interactivefics = 'InteractiveFics',
  imagedownloader = 'Image download center',
  improvedtube = 'Improve YouTube',
  languagegirl = 'LanguageGirl',
  lindie = 'Lindie Botes',
  lnmai = 'Multiple Tools for Facebook',
  linguix = 'Linguix',
  mp = 'Google Meet Plus',
  meow = 'Meow',
  natalia = 'Natalia Garza',
  noteboard = 'Note Board',
  nod = 'Nod',
  pagemarker = 'Page Marker',
  prp = 'Penny Rich Publications',
  perfectpixel = 'PerfectPixel',
  podstation = 'podStation',
  plan = 'Plan',
  stayfocusd = 'StayFocusd',
  superdarkmode = 'Super Dark Mode',
  shea = 'Shea Jordan',
  smartlink = 'Night Mode',
  stickynotes = 'Sticky Notes',
  substital = 'Substital',
  tcubed = 'tCubed',
  timleland = 'Weather',
  urbaneve = 'TheUrbanEve',
  uncannycookieclicker = 'Uncanny Cookie Clicker',
  volumebooster = 'Volume Booster',
  visualeffects = 'Visual Effects',
}
