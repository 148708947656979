import { FC, memo } from 'react';

import { css } from '@emotion/react';
import { ToucanComponents, ToucanColors } from '@jointoucan/toucan-design';
import { Box } from '@mui/material';

const { Typography } = ToucanComponents;

interface PronunciationProps {
  pronunciation: string;
}

export const Pronunciation: FC<PronunciationProps> = memo(({ pronunciation }) => (
  <Box
    pl={2}
    pr={2}
    pt={0.5}
    pb={0.5}
    width="100%"
    borderRadius="10px 10px 0 0"
    css={css`
      background-color: ${ToucanColors.blue[100]};
    `}
  >
    <Typography variant="xs" color={ToucanColors.gray[500]}>
      {pronunciation}
    </Typography>
  </Box>
));
